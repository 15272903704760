<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage" @input="onPerPageChange">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="assessments && assessments.data ? assessments.data : []"
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="assessments && assessments.total ? assessments.total : 0"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      :loading="isLoadingTable"
    >
      <!-- For number -->
      <b-table-column
        field="index"
        width="20"
        numeric
        label="No"
        v-slot="props"
      >
        {{ props.index + assessments.from }}
      </b-table-column>

      <!-- For title -->
      <b-table-column field="title" label="Assessment" sortable v-slot="props">
        <router-link :to="{ path: `/assessment/edit/${props.row.id}` }">
          {{ props.row.title }}
        </router-link>
      </b-table-column>

      <!-- For created at -->
      <b-table-column
        field="created_at"
        label="Created At"
        sortable
        v-slot="props"
      >
        {{ props.row.createdAt }}
      </b-table-column>

      <!-- For status -->
      <b-table-column field="status" label="Status" sortable>
        <span class="tag is-warning">Inactive</span>
      </b-table-column>

      <!-- For action -->
      <b-table-column field="action" centered label="Action" v-slot="props">
        <b-button
          type="icon-button"
          icon-left="link"
          @click="generateLink(props.row.code)"
          title="Generate Link Assessment"
        ></b-button>

        <router-link
          :to="'/assessment/show/' + props.row.id"
          title="Show Assessment"
        >
          <b-button type="icon-button" icon-left="eye"></b-button>
        </router-link>

        <b-button
          type="icon-button"
          icon-left="undo-alt"
          title="Restore Assessment"
          @click="restorePopup(props.row.id)"
        ></b-button>

        <b-button
          type="icon-button"
          icon-left="copy"
          title="Duplicate Assessment"
          @click="duplicatePopup(props.row.id)"
        ></b-button>

        <b-button
          type="icon-button"
          icon-left="trash"
          title="Delete Assessment"
          @click="deletePopup(props.row.id)"
        ></b-button>
      </b-table-column>
      <template slot="bottom-left">
        {{ assessments && assessments.from ? assessments.from : 0 }}
        -
        {{ assessments && assessments.to ? assessments.to : 0 }}
        from
        {{ assessments && assessments.total ? assessments.total : 0 }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>

    <b-modal
      :active.sync="isModalLinkActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <modal-copy-link
        v-if="isModalLinkActive"
        :code="code"
        @close-modal="onClose"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import ModalCopyLink from '@/components/V2/ModalCopyLink'

export default {
  components: {
    ModalCopyLink,
  },
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoading: false,
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'created_at',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      status: 'Inactive',
      code: '',
      isModalLinkActive: false,
    }
  },
  mounted() {
    this.fetchData(
      this.perPage,
      this.currentPage,
      this.sortField,
      this.sortOrder,
      this.search,
      this.status
    )
  },
  methods: {
    ...mapActions({
      fetchAssessments: 'newAssessment/fetchAssessments',
      restoreAssessmentData: 'newAssessment/restoreAssessment',
      duplicateAssessmentData: 'newAssessment/duplicateAssessment',
      deleteAssessmentData: 'newAssessment/deleteAssessment',
    }),

    // For generate link in index
    generateLink(code) {
      this.isModalLinkActive = true
      this.code = code
    },

    // For close modal link in index
    onClose() {
      this.isModalLinkActive = false
      this.code = ''
    },

    // For search assessment
    searchAssessment: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status
        )
      }
    }, 500),

    // For restore popup
    restorePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Restore Assessment',
        message: `Are you sure want to restore this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, restore it!',
        type: 'is-hcc',
        onConfirm: () => this.restoreAssessment(id),
      })
    },

    // For restore assessment
    async restoreAssessment(id) {
      this.isLoading = true

      try {
        await this.restoreAssessmentData(id)

        this.success('Restore Assessment Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )

      this.isLoading = false
    },

    // For duplicate popup
    duplicatePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Duplicate Assessment',
        message: `Are you sure want to duplicate this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, duplicate it!',
        type: 'is-hcc',
        onConfirm: () => this.duplicateAssessment(id),
      })
    },

    // For duplicate assessment
    async duplicateAssessment(id) {
      this.isLoading = true

      try {
        await this.duplicateAssessmentData(id)

        this.success('Duplicate Assessment Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )

      this.isLoading = false
    },

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Assessment',
        message: `Are you sure want to delete this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-hcc',
        onConfirm: () => this.deleteAssessment(id),
      })
    },

    // For delete assessment
    async deleteAssessment(id) {
      this.isLoading = true

      try {
        const response = await this.deleteAssessmentData(id)

        if (response.data.status == 500) {
          this.danger('Assessment already in use')
        } else {
          this.success('Delete Assessment Success')
        }
      } catch (err) {
        this.danger(err)
      }

      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )

      this.isLoading = false
    },

    // For fetching data
    async fetchData(perPage, page, sortField, sortOrder, search, status) {
      let data = {
        perPage: perPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        search: search,
        status: status,
      }

      this.isLoadingTable = true
      try {
        await this.fetchAssessments(data)
      } catch (err) {
        this.danger(err)
      }
      this.isLoadingTable = false
    },

    // For Page Change
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For Sorting Data
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  watch: {
    search: function(val) {
      this.searchAssessment(val)
    },
  },
  computed: {
    ...mapGetters({
      assessments: 'newAssessment/getNewAssessments',
    }),
  },
}
</script>

<style scoped>
.modal-copy-link {
  width: 500px !important;
}
</style>
