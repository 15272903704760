<template>
  <div class="modal-card modal-copy-link" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Link For Answer</p>
      <button type="button" class="delete" @click="onClose" />
    </header>
    <section class="modal-card-body">
      <b-field class="mb-1" label="Link for answer" :label-position="'on-border'">
        <b-input
          type="text"
          expanded
          class="url-link"
          :value="link"
          readonly
        ></b-input>
        <p class="control">
          <b-button
            icon-left="clipboard"
            v-clipboard:copy="link"
            v-clipboard:success="onSuccessCopyMessage"
            v-clipboard:error="onErrorCopyMessage"
          ></b-button>
        </p>
      </b-field>
      <div :class="`${message.type == 'success' ? 'success' : 'required'} mb-4`">
        {{ message.text }}
      </div>

      <b-field class="mb-1" label="Link for preview" :label-position="'on-border'">
        <b-input
          type="textarea"
          expanded
          class="url-link"
          v-model="linkPreview"
        ></b-input>
        <p class="control">
          <b-button
            icon-left="clipboard"
            v-clipboard:copy="linkPreview"
            v-clipboard:success="onSuccessCopyMessagePreview"
            v-clipboard:error="onErrorCopyMessagePreview"
          ></b-button>
        </p>
      </b-field>
      <div :class="`${messagePreview.type == 'success' ? 'success' : 'required'} mb-4`">
        {{ messagePreview.text }}
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="onClose">Close</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'modal-copy-link',
  props: {
    code: {
      default: () => {},
    },
  },
  data() {
    return {
      message: {
        type: '',
        text: '',
      },
      link: '',
      messagePreview: {
        type: '',
        text: '',
      },
      linkPreview: '',
    }
  },
  mounted() {
    this.generateLink(this.code)
    this.generateLinkPreview(this.code)
  },
  methods: {
    // For generate link in index
    generateLink(code) {
      this.isModalLinkActive = true
      this.link = process.env.VUE_APP_BASE_URL + '/assessment/' + code
    },
    generateLinkPreview(code) {
      this.isModalLinkActive = true

      let randomNumber = Math.floor(Math.random() * 9999999999) + 100000
      this.linkPreview = `${process.env.VUE_APP_BASE_URL}/assessment/${code}?app_url=capture.co.id/&id_tes=${randomNumber}&nit=${randomNumber}&nama=${randomNumber}&email=${randomNumber}@example.com&cid=${randomNumber}&uid=${randomNumber}&preview=preview`
    },
    // For close modal link in index
    onClose() {
      this.message.text = ''
      this.message.type = ''
      this.$emit('close-modal')
    },

    // For after success copy message link in index
    onSuccessCopyMessage() {
      this.message.text = 'Successfully copy link!'
      this.message.type = 'success'
    },

    // For after error copy message link in index
    onErrorCopyMessage() {
      this.message.text = 'Error copy link!'
      this.message.type = 'error'
    },

    // For after success copy message link in index
    onSuccessCopyMessagePreview() {
      this.messagePreview.text = 'Successfully copy link preview!'
      this.messagePreview.type = 'success'
    },

    // For after error copy message link in index
    onErrorCopyMessagePreview() {
      this.messagePreview.text = 'Error copy link preview!'
      this.messagePreview.type = 'error'
    },
  },
}
</script>
